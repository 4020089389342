<script setup lang="ts">
import { useTemplate } from '@konnektor/scripter-library';
import MainLayoutHeader from 'src/components/layout/MainLayoutHeader.vue';
import MainLayoutToolbar from 'src/components/layout/MainLayoutToolbar.vue';
import { computed } from 'vue';

const { template } = useTemplate();

const hasLoaded = computed(() => template !== null);

</script>

<template>
  <q-layout view="lhh lpr lFf">
    <div
      v-if="hasLoaded"
      data-cy="container"
    >
      <MainLayoutHeader />
      <q-page-container
        class="q-page-container-margin tw-container tw-mx-auto"
      >
        <q-page padding>
          <MainLayoutToolbar class="q-mb-md" />
          <router-view />
        </q-page>
      </q-page-container>
    </div>
  </q-layout>
</template>
