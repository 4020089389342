export enum RouteName {
  RecordPage = 'record',
  CallListPage = 'call-list',
  PositivePage = 'positive',
  NegativePage = 'negative',
  AddonPage = 'addon',
  CalendarPage = 'calendar',
  VoicelogPage = 'voicelog',
  ErrorPage = 'error'
}
