import { Context } from 'vm';

type Middleware = (params: unknown) => Promise<boolean>;

/**
 * Run all middlewares set in routes.ts
 */
function middlewarePipeline (context: Context, middlewares: Middleware[], index: number) {
  const middleware = middlewares[index];
  if (!middleware) return context.next;
  return async () => {
    const nextMiddleware = middlewarePipeline(context, middlewares, index + 1);
    await middleware({ ...context, pipeline: nextMiddleware, next: context.next });
  };
}

export default middlewarePipeline;
