import { captureExceptionWithNotification, useUser } from '@konnektor/scripter-library';
import { RouteName } from 'src/enums/route-name-enum';

export async function UserMiddleware (/* { to, from, next, store } */ { to, next, pipeline }) {
  const { user, fetchAndSetAuthenticatedUser } = useUser();

  if (user.value === null) {
    try {
      await fetchAndSetAuthenticatedUser();
      return pipeline();
    } catch (err) {
      captureExceptionWithNotification(err, 'Er is een fout opgetreden bij het ophalen van de gebruiker');

      const params = {
        campaignCode: to.params?.campaignCode,
        recordId: to.params?.recordId,
      };

      return next({ name: RouteName.ErrorPage, params });
    }
  }

  return pipeline();
}
