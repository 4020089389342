import { captureExceptionWithNotification, useLeadRecord } from '@konnektor/scripter-library';
import { RouteName } from 'src/enums/route-name-enum';

export async function LeadRecordMiddleware ({ to, next, pipeline }) {
  const { fetchAndUpdateCurrentRecord } = useLeadRecord();

  try {
    await fetchAndUpdateCurrentRecord();
    return pipeline();
  } catch (err) {
    captureExceptionWithNotification(err, 'Er is een fout opgetreden bij het ophalen van de lead');

    const params = {
      campaignCode: to.params?.campaignCode,
      recordId: to.params?.recordId,
    };

    return next({ name: RouteName.ErrorPage, params });
  }
}
