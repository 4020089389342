<script setup lang="ts">
import { useClient, useDeveloper } from '@konnektor/scripter-library';
import MainLayoutToolbarDetails from 'src/components/layout/MainLayoutToolbarDetails.vue';
import MainLayoutToolbarPhoneActions from 'src/components/layout/MainLayoutToolbarPhoneActions.vue';
import { useToolbar } from 'src/composables/useToolbar';
import BackButton from 'src/components/shared/BackButton.vue';
import { ref } from 'vue';
import DeveloperDrawer from '../DeveloperDrawer.vue';

const { isDeveloper } = useDeveloper();
const { client } = useClient();
const { display } = useToolbar();

const isDeveloperDrawerVisible = ref(false);

function toggleDeveloperDrawer () {
  isDeveloperDrawerVisible.value = !isDeveloperDrawerVisible.value;
}
</script>

<template>
  <div
    class="layout-toolbar"
    data-cy="toolbar"
  >
    <BaseCard>
      <BaseRow items-align="center">
        <BaseCol
          :cols="8"
          justify="start"
          items-align="center"
          data-test="back-button-col"
        >
          <BackButton />
          <img
            class="q-ml-lg q-mr-lg"
            height="30"
            :src="client.logo"
          >
          <MainLayoutToolbarDetails />
        </BaseCol>
        <BaseCol
          :cols="4"
          justify="end"
          items-align="center"
        >
          <BaseButton
            v-if="isDeveloper"
            class="tw-h-12 tw-w-12 tw-ml-3"
            icon="code"
            @click="toggleDeveloperDrawer"
          />
          <MainLayoutToolbarPhoneActions v-if="display.isPhoneActionVisible" />
        </BaseCol>
      </BaseRow>
    </BaseCard>
    <template v-if="isDeveloper">
      <teleport to="body">
        <DeveloperDrawer
          v-model="isDeveloperDrawerVisible"
        />
      </teleport>
    </template>
  </div>
</template>

<style scoped lang="scss">
.layout-toolbar {
  position: relative;
  z-index: 2000; // prevents the toolbar from being hidden by the header (quasar z-index)
  margin-top: -4.5em;
}
</style>
