<script setup lang="ts">
import {
  useCampaign, useLeadRecord, useTemplate, useUser,
} from '@konnektor/scripter-library';
import { useToolbar } from 'src/composables/useToolbar';

const { template } = useTemplate();
const { record, hasConfirmedOffer, isRecordExclusiveToCurrentUser } = useLeadRecord();
const { fullCode } = useCampaign();
const { user, hasReachedScheduledCallbackLimit } = useUser();
const { display } = useToolbar();

</script>

<template>
  <div>
    Projectnummer: {{ fullCode }}
    <span v-if="display.isDetailVisible">
      | TK ID: {{ record.id }} |
      Orderportal ID: {{ record.order_portal_reference }}
    </span>
    <br>
    <div v-if="display.isDetailVisible">
      <div
        v-if="hasConfirmedOffer"
        data-test="confirmed-offer-text"
      >
        De sale is bevestigd
      </div>

      <template v-if="template.config.is_personal_callbacks_visible">
        <span
          v-if="hasReachedScheduledCallbackLimit"
          data-test="max-callback-reached-message"
        >
          Max personal callbacks bereikt: {{ template?.config.max_personal_callbacks }}
        </span>

        <span
          v-else
          data-test="callbacks-scheduled-message"
        >
          Aantal personal callbacks ingepland: {{ user?.totalScheduledCallbacks }}
        </span>
      </template>

      <span
        v-if="record.certified_comment"
        class="text-red"
        data-test="certified-comment-text"
      >
        TQIS Opmerking: {{ record.certified_comment }}
      </span>

      <div
        v-if="isRecordExclusiveToCurrentUser"
        data-test="lock-message"
      >
        Dit record is gelocked op jouw naam. <br>
      </div>
    </div>
  </div>
</template>
