<script setup lang="ts">
import { setColorScheme, useUser } from '@konnektor/scripter-library';
import { onMounted } from 'vue';

const { user } = useUser();

// TODO: move theme to template settings
const theme = {
  improvers: {
    primary: '#1E53bc',
    logo: 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_improvers_white.svg',
  },
  certified: {
    primary: '#45E4BF',
    logo: 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_certified_v2_blue.svg',
  },
};
const activeTheme = theme[user.value?.location === '1' ? 'improvers' : 'certified'];
const appVersion = process.env.VITE_APP_APP_VERSION;

onMounted(() => {
  setColorScheme({
    primary: activeTheme.primary,
  });
});
</script>

<template>
  <q-header
    elevated
    class="bg-primary text-white"
  >
    <span class="text-grey-6">
      {{ appVersion }}
    </span>
    <div
      class="header bg-primary"
    >
      <img
        :src="activeTheme.logo"
      >
    </div>
  </q-header>
</template>

<style lang="scss" scoped>
.header {
  height: 7rem;
  display: flex;
  justify-content: center;

  img {
    margin-top: 1.5rem;
    height: 2rem
  }
}
</style>
