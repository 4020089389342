import { route } from 'quasar/wrappers';
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';
import middlewarePipeline from './middleware-pipeline';

import routes from './routes';

export default route((/* { store, ssrContext } */) => {
  const createHistory = process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory;
  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  // hotfix: https://github.com/vitejs/vite/issues/11804#issuecomment-1406182566
  // TODO: wait for vite fix: https://github.com/vitejs/vite/issues/12080
  // Will refresh the page when a dynamic import fails
  Router.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      window.location = to.fullPath as unknown as Location;
    }
  });

  // Run the middleware(s) using the beforeEach hook
  Router.beforeEach((to, from, next) => {
    if (!to.meta.middlewares) {
      return next();
    }
    const { middlewares } = to.meta;
    const context = {
      to,
      from,
      next,
    };
    return middlewares[0]({
      ...context,
      pipeline: middlewarePipeline(context, middlewares, 1),
    });
  });

  return Router;
});
