import { RouteName } from 'src/enums/route-name-enum';
import { reactive, readonly, ref } from 'vue';

interface ToolbarDisplay {
  /**
   * Whether the detail view (MainLayoutToolbarDetails) is currently visible in the toolbar.
   */
  isDetailVisible: boolean;
  /**
   * Whether the phone action is currently visible in the toolbar.
   */
  isPhoneActionVisible: boolean;

}

const display = reactive<ToolbarDisplay>({
  isDetailVisible: true,
  isPhoneActionVisible: true,
});

// TODO: merge to object like display
const backButtonRouteName = ref<RouteName | null>(RouteName.RecordPage);
const isBackButtonDisabled = ref(false);

export function useToolbar () {
  function setToolbarDisplay (options: ToolbarDisplay) {
    Object.assign(display, options);
  }

  function setBackButtonRouteName (routeName: RouteName | null) {
    backButtonRouteName.value = routeName;
  }

  function setBackButtonAsPauseTeleknowledge () {
    setBackButtonRouteName(null);
  }

  function setBackButtonDisabled () {
    isBackButtonDisabled.value = true;
  }

  function setBackButtonEnabled () {
    isBackButtonDisabled.value = false;
  }

  return {
    display: readonly(display),
    backButtonRouteName: readonly(backButtonRouteName),
    isBackButtonDisabled: readonly(isBackButtonDisabled),
    setBackButtonRouteName,
    setBackButtonAsPauseTeleknowledge,
    setToolbarDisplay,
    setBackButtonDisabled,
    setBackButtonEnabled,
  };
}
