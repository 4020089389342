<script setup lang="ts">
import { useLeadRecord, useDeveloper, LeadRecord } from '@konnektor/scripter-library';
import { reactive } from 'vue';

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  'update:modelValue': [val: boolean];
}>();

const { record } = useLeadRecord();
const { setRecordData } = useDeveloper();

const localRecord = reactive<LeadRecord>({ ...record.value });

function onClickSave () {
  setRecordData(localRecord);
  emit('update:modelValue', false);
}

function onClickCancel () {
  emit('update:modelValue', false);
}

</script>

<template>
  <q-dialog
    :model-value="modelValue"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <q-card>
      <q-card-section>
        <div class="row">
          <div
            v-for="(value, key) in localRecord"
            :key="key"
            class="col-6 q-pa-sm"
          >
            <q-input
              v-model="localRecord[key]"
              :label="key"
            />
          </div>
        </div>
      </q-card-section>

      <q-card-actions>
        <q-btn
          label="Cancel"
          @click="onClickCancel"
        />
        <q-btn
          label="Save"
          @click="onClickSave"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
