<script setup lang="ts">
import { useIntervalFn } from '@vueuse/shared';
import {
  usePhone, useLeadRecord, DialerButton, PhoneButton, EndCallButton, useTemplate,
} from '@konnektor/scripter-library';
import { ref, computed, onMounted } from 'vue';

const { record, isRecordExclusiveToCurrentUser } = useLeadRecord();
const { isPhoneInactive, hasMadeOutgoingPhoneCallAttempt } = usePhone();
const { template } = useTemplate();
const { call } = usePhone();

const countdownTime = ref(template.value?.config?.auto_dial_after_opening_record_in_seconds ?? 5);

const isCountdownEnabled = computed(
  () => {
    if (countdownTime.value <= 0) {
      return false;
    }

    if (!record.value?.phone_number_2 && !record.value?.phone_number_1) {
      return false;
    }

    if (isRecordExclusiveToCurrentUser.value) {
      return false;
    }

    if (hasMadeOutgoingPhoneCallAttempt.value) {
      return false;
    }

    if (isPhoneInactive.value) {
      return true;
    }

    return false;
  },
);

function callPhoneNumber () {
  call(record.value?.phone_number_1 ?? record.value?.phone_number_2);
}

const { pause: pauseCountdown } = useIntervalFn(
  () => {
    countdownTime.value -= 1;
    if (countdownTime.value === 0) {
      pauseCountdown();

      if (isPhoneInactive.value) {
        callPhoneNumber();
      }
    }
  },
  1000,
  { immediate: isCountdownEnabled.value },
);

function onCancelCountdown () {
  countdownTime.value = 0;
  pauseCountdown();
}

onMounted(() => {
  if (countdownTime.value === 0) {
    callPhoneNumber();
  }
});

</script>

<template>
  <DialerButton class="tw-ml-3" />
  <PhoneButton
    :countdown-time="countdownTime"
    :is-countdown-enabled="isCountdownEnabled"
    class="tw-ml-3"
    @stop-countdown="onCancelCountdown"
  />
  <EndCallButton
    :is-countdown-enabled="isCountdownEnabled"
    class="tw-ml-3"
    @stop-countdown="onCancelCountdown"
  />
</template>
