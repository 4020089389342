<script setup lang="ts">
import { BackButton } from '@konnektor/scripter-library';
import { useToolbar } from 'src/composables/useToolbar';

const { backButtonRouteName, isBackButtonDisabled } = useToolbar();

</script>

<template>
  <BackButton
    :route-name="backButtonRouteName"
    :disabled="isBackButtonDisabled"
  />
</template>
