import { RouteRecordRaw } from 'vue-router';
import MainLayout from 'src/layouts/MainLayout.vue';
import { RouteName } from 'src/enums/route-name-enum';
import { LeadRecordMiddleware } from './middlewares/record-middleware';
import { UserMiddleware } from './middlewares/user-middleware';
import { TemplateMiddleware } from './middlewares/template-middleware';

// TODO: add top-level middleware support
export const routes: RouteRecordRaw[] = [
  {
    path: '/campaign/:campaignCode',
    component: MainLayout,
    children: [
      {
        name: RouteName.CallListPage,
        path: 'callbacks',
        component: () => import('src/pages/default/CallbackListPage.vue'),
        meta: {
          middlewares: [TemplateMiddleware, UserMiddleware],
        },
      },
      {
        path: 'record/:recordId',
        name: RouteName.RecordPage,
        component: () => import('src/pages/default/RecordPage.vue'),
        meta: {
          middlewares: [TemplateMiddleware, UserMiddleware, LeadRecordMiddleware],
        },
      },
      {
        path: 'record/:recordId/addon',
        name: RouteName.AddonPage,
        component: () => import('src/pages/default/RecordAddonPage.vue'),
        meta: {
          middlewares: [TemplateMiddleware, UserMiddleware, LeadRecordMiddleware],
        },
      },
      {
        name: RouteName.PositivePage,
        path: 'record/:recordId/positive',
        component: () => import('src/pages/default/RecordPositivePage.vue'),
        meta: {
          middlewares: [TemplateMiddleware, UserMiddleware, LeadRecordMiddleware],
        },
      },
      {
        name: RouteName.NegativePage,
        path: 'record/:recordId/negative',
        component: () => import('src/pages/default/RecordNegativePage.vue'),
        meta: {
          middlewares: [TemplateMiddleware, UserMiddleware, LeadRecordMiddleware],
        },
      },
      {
        name: RouteName.CalendarPage,
        path: 'record/:recordId/calendar',
        component: () => import('src/pages/default/RecordCalendarPage.vue'),
        meta: {
          middlewares: [TemplateMiddleware, UserMiddleware, LeadRecordMiddleware],
        },
      },
      {
        name: RouteName.VoicelogPage,
        path: 'record/:recordId/voicelog',
        component: () => import('src/pages/default/RecordVoicelogPage.vue'),
        meta: {
          middlewares: [TemplateMiddleware, UserMiddleware, LeadRecordMiddleware],
        },
      },
    ],
  },
  {
    name: RouteName.ErrorPage,
    path: '/error/:campaignCode?/:recordId?',
    component: () => import('src/pages/ErrorPage.vue'),
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('src/pages/ErrorNotFound.vue'),
  },
];

export default routes;
