<script setup lang="ts">
import { RouteName } from 'src/enums/route-name-enum';
import { useRoute, useRouter } from 'vue-router';
import {
  ResultCode,
  useCampaign, useDeveloper, useLeadRecord, useNotification, useUser,
} from '@konnektor/scripter-library';
import { useQuasar } from 'quasar';
import { ref } from 'vue';
import DeveloperRecordDialog from './DeveloperRecordDialog.vue';

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  'update:modelValue': [val: boolean];
}>();

const { warning } = useNotification();
const router = useRouter();
const currentRoute = useRoute();
const { record } = useLeadRecord();
const { fullCode } = useCampaign();
const { dialog } = useQuasar();
const { user } = useUser();
const {
  developerSettings,
  setDeveloperSettings,
  resetPhoneState,
  setRecordData,
} = useDeveloper();

const routes = Object.values(RouteName);
const isEditRecordDialogVisible = ref(false);

/**
 * Show the JSON representation of the current lead record in a dialog.
 */
function showRecord () {
  dialog({
    title: 'Record',
    message: `<pre>${JSON.stringify(record.value, null, 2)}</pre>`,
    html: true,
    ok: true,
    cancel: false,
  });
}

/**
 * Navigate to a different route within the application.
 * Automatically uses current campaignCode and recordId, if available.
 */
function navigate (route: RouteName) {
  const params = currentRoute.params as Record<string, string | number>;

  if (!params.campaignCode) {
    params.campaignCode = fullCode.value ?? 'ABCD12345678';

    warning({
      message: 'Campaign code not found, using ABCD12345678 (might break flow)',
    });
  }

  if (!params.recordId) {
    params.recordId = record.value?.id ?? 0;

    warning({
      message: 'Record ID not found, using 0 as ID (might break flow)',
    });
  }

  router.push({
    name: route,
    params,
  });
}

/**
 * Sets the current record as a callback record.
 */
function onClickSetAsCallback () {
  setRecordData({
    ...record.value,
    result_code: ResultCode.CALLBACK_REQUEST_RESULT,
    exclusive_user_id: user.value?.id,
    exclusive_user_date: new Date().toISOString(),
  });
}

/**
 * Resets the current record to a 'new' state.
 */
function onClickSetAsNewRecord () {
  setRecordData({
    ...record.value,
    result_code: null,
    exclusive_user_id: null,
    exclusive_user_date: null,
  });
}

/**
 * Enables or disables off-hours calling.
 * @param val - The value indicating if off-hours calling is enabled.
 */
function setOffHoursCalling (val: boolean) {
  setDeveloperSettings({
    ...developerSettings.value,
    canCallOffHours: val,
  });
}

</script>

<template>
  <q-drawer
    :model-value="modelValue"
    border
    content-class="q-pa-md"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <q-toolbar>
      <q-toolbar-title>
        Developer Tools
      </q-toolbar-title>
    </q-toolbar>

    <q-separator />

    <q-list>
      <q-item-label
        header
        class="q-mb-xs"
      >
        Actions
      </q-item-label>

      <q-item>
        <q-item-section>
          <q-btn
            label="Show Record"
            @click="showRecord"
          />
        </q-item-section>
      </q-item>

      <q-item>
        <q-item-section>
          <q-btn
            label="Edit Record"
            @click="isEditRecordDialogVisible = true"
          />
        </q-item-section>
      </q-item>

      <q-item>
        <q-item-section>
          <q-btn
            label="Reset phone (timers and state)"
            @click="resetPhoneState"
          />
        </q-item-section>
      </q-item>

      <q-item>
        <q-item-section>
          <q-btn
            label="Set as callback"
            @click="onClickSetAsCallback"
          />
        </q-item-section>
      </q-item>

      <q-item>
        <q-item-section>
          <q-btn
            label="Set as new record"
            @click="onClickSetAsNewRecord"
          />
        </q-item-section>
      </q-item>

      <q-item>
        <q-item-section>
          <q-toggle
            :model-value="developerSettings.canCallOffHours"
            label="Enable Off-Hours Calling"
            @update:model-value="setOffHoursCalling"
          />
          <div class="q-mb-xs text-caption">
            Prefixes the phone number with a '3' to allow calling outside of business hours
          </div>
        </q-item-section>
      </q-item>

      <q-separator spaced />

      <q-item-label
        header
        class="q-mb-xs"
      >
        Navigate
      </q-item-label>

      <q-item
        v-for="(route, index) in routes"
        :key="index"
        v-ripple
        clickable
        exact
        @click="navigate(route)"
      >
        <q-item-section>
          {{ route }}
        </q-item-section>
      </q-item>
    </q-list>
  </q-drawer>
  <teleport to="body">
    <DeveloperRecordDialog v-model="isEditRecordDialogVisible" />
  </teleport>
</template>
